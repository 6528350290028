<template>
    <div id="dc-new-ticket">
        <b-button class="dc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h3>New Ticket</h3>
        <hr />

        <div id="dc-sale-info-container">
            <b-form-select id="dc-customer-select" v-model="selectedCustomerId" :options="customerOptions"></b-form-select>
            <b-form-input id="dc-truck-input" v-model="selectedTruck" type="text" placeholder="Truck"></b-form-input>
        </div>

        <b-form @submit="onSubmitNewTicket">
            <table>
                <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Description</th>
                    <th>QTY</th>
                    <!-- <th>RATE</th> -->
                    <!-- <th>Amount</th> -->
                    <th>Action</th>
                </tr>
                <tr v-for="(item, index) in items" :key="item.message">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <b-form-select v-model="item.productId" :options="productOptions"></b-form-select>
                    </td>
                    <td><b-form-textarea v-model="item.description" placeholder="Description" rows="2" max-rows="3"></b-form-textarea></td>
                    <td><b-form-input v-model="item.quantity" type="number" placeholder="Quantity"></b-form-input></td>
                    <!-- <td><b-form-input v-model="item.pricePerUnit" type="number" placeholder="Rate" min="0"></b-form-input></td> -->
                    <!-- <td>{{ item.quantity * item.pricePerUnit }}</td> -->
                    <td class="dc-action-column">
                        <div>
                            <b-icon-trash-fill class="dc-icon-delete" @click="onClickDeleteRow(index)"></b-icon-trash-fill>
                        </div>
                    </td>
                </tr>
            </table>

            <div id="dc-action-container">
                <b-button class="dc-button-primary dc-add-item" variant="primary" @click="onClickAddRow()">Add</b-button>
                <b-button class="dc-button-primary dc-create-ticket" variant="primary" @click="onClickCreateTicket">Create</b-button>
            </div>

            <!-- <b-button class="dc-button-primary" type="submit" variant="primary">Add</b-button> -->
        </b-form>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import LineItemAPICalls from "./../lineItem/APICalls";
import CustomerAPICalls from "./../customer/APICalls";
import ProductAPICalls from "./../product/APICalls";

export default {
    name: "NewTicket",
    data() {
        return {
            customerOptions: [],
            customersList: [],
            items: [{}],
            productOptions: [],
            productsList: [],
            selectedCustomerId: null,
            selectedTruck: "",
            // selectedProduct: null,
        };
    },
    methods: {
        onClickAddRow() {
            let item = this.items[this.items.length - 1];
            if (!item.productId || !item.quantity) return;
            this.items.push({});
        },
        onClickCreateTicket() {
            if (!this.selectedCustomerId) return;
            this.createNewTicket();
        },
        onClickDeleteRow(index) {
            if (index < 1) return;
            console.log("Delete Row", index);
        },
        onSubmitNewTicket(event) {
            event.preventDefault();
            console.log("Add New Ticket", this.form);
        },
        async createNewTicket() {
            let params = {
                customerId: this.selectedCustomerId,
                truck: this.selectedTruck,
            };

            // Create Sale
            let response = await APICalls.createTicket(params);
            let saleId = response.id;
            console.log("Ticket Id", saleId);

            //  Create LineItems
            for (const index in this.items) {
                let item = this.items[index];
                console.log(item);
                if (item.productId && item.quantity) {
                    let params = {
                        itemId: parseInt(item.productId),
                        quantity: parseFloat(item.quantity),
                        pricePerUnit: item.pricePerUnit,
                        description: item.description,
                    };
                    let response = await LineItemAPICalls.createLineItem(saleId, params);
                    console.log(response);
                }
            }
            this.$router.back();
        },
        async getCustomers() {
            let customers = await CustomerAPICalls.getCustomers();
            this.customersList = _.sortBy(customers, (cust) => cust.name);
        },
        async getProducts() {
            let products = await ProductAPICalls.getProducts();
            this.productsList = _.sortBy(products, (cust) => cust.name);
        },
    },
    watch: {
        customersList: function(val) {
            this.customerOptions = [];
            this.customerOptions.push({ value: null, text: "Select customer" });
            _.each(val, (v) => {
                let option = {};
                option.value = v.id;
                option.text = v.company;
                this.customerOptions.push(option);
            });
        },
        productsList: function(val) {
            this.productOptions = [];
            this.productOptions.push({ value: null, text: "Select product" });
            _.each(val, (v) => {
                let option = {};
                option.value = v.id;
                option.text = v.name + " " + v.itemType.name + " " + v.sku;
                option.text = `${v.name} (${v.itemType.name}) (${v.sku})`;
                this.productOptions.push(option);
            });
        },
    },
    mounted() {
        this.getCustomers();
        this.getProducts();
    },
};
</script>

<style lang="scss">
#dc-new-ticket {
    #dc-sale-info-container {
        display: flex;
        margin: 30px 0px;
        justify-content: space-between;

        #dc-customer-select {
            width: 30%;
        }

        #dc-truck-input {
            width: 30%;
        }
    }

    table {
        width: 100%;

        tr {
            th {
                padding: 10px;
            }

            td {
                padding: 10px;
            }
        }

        .dc-action-column {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }
    }
    form {
        margin-top: 20px;

        .col-form-label {
            font-size: 20px;
        }

        #dc-companyname-container,
        #dc-ticketname-container,
        #dc-balance-container {
            display: inline-block;
            width: 33.33%;
        }

        #dc-ticketname-container,
        #dc-balance-container {
            padding-left: 40px;
        }

        #dc-email-container,
        #dc-phone-container,
        #dc-carryover-container {
            display: inline-block;
            width: 33.33%;
        }

        #dc-phone-container,
        #dc-carryover-container {
            padding-left: 40px;
        }

        #dc-address-container,
        #dc-notes-container {
            display: inline-block;
            width: 50%;
        }

        #dc-notes-container {
            padding-left: 50px;
        }

        .dc-button-primary {
            width: 180px;
            margin-top: 20px;
        }

        .dc-button-primary.dc-create-ticket {
            margin-left: 20px;
        }

        #dc-action-container {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
